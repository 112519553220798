.backGround{
    position: relative;
    background: #95acb3;
    min-height: 100vh;
    height: 100%;
    width : 100vw;
    padding-bottom: 16px;
}

.backGround2{
    position: relative;
    background: radial-gradient(#76b2fe, #b69efe);
    min-height: 100vh;
    height: 100%;
    width : 100vw;
}

