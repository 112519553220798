
.mainDiv{
    height: 100vh;
    background-image: url('./simple-icon.jpg');
    /* background-size: 205vh 85vh; */
    opacity: 80%;
  }
  
  .LOGO{
      height: 50px;
      display: block;
      margin: auto;
      border-radius: 20px;
  }
  .Container{
     max-width: 900px;
     /* background-color: red; */
     border: 1px solid rgb(8, 8, 8);
     margin: auto;
     height: 60vh;
     padding: 33px;
     overflow-y: auto;
  }
  
  
  #send-container{
     
      display: block;
      margin: auto;
      text-align: center;
      margin-top: 5px;
      max-width: 800px;
  }
  #messageInp{
      width: 92%;
      border: 2px solid black;
      border-radius: 6px;
      height: 34px;
      font-size: 15px;
  }
  
  
  .button{
      cursor: pointer;
      border: 2px solid black;
      border-radius: 10px;
      height: 34px;
  }
  .button:hover{
      background-color: rgb(11, 114, 225);
  }
  .message{
      background-color: rgb(18, 84, 62);
      width: 24%;
      padding: 10px;
      margin: 17px 12px;
      border: 2px solid black;
      border-radius: 10px;
      color: white;
  }
  
  .left{
      float: left;
      clear: both;
      background-color: rgb(9, 9, 9);
  }
  
  .heading{
      /* margin-top: 10px; */
      text-align: center;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 40px;
      color: aliceblue;
  }
  .right{
      float: right;
      clear: both;
  }

  span{
      color: white;
  }