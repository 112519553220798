#cont {
  height: 91.5vh;
  /* decreasing opacity of image */
  background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    url("../../img/home.jpg") center/cover no-repeat;
  align-self: stretch;
  overflow-x: hidden; /*hiding overflow in width*/
}

#heading {
  margin-left: 20%;
}

#searchSection {
  max-width: 60%;
  margin: 0 auto;
  margin-top: 10%;
  margin-bottom: 5%;
}

#sloganCardImg {
  height: 60px;
  width: 60px;
  margin: 10px auto;
  border-radius: 8px;
}
