.dashboard {
  max-width: 100%;
  overflow-x: hidden;
  margin: auto;
  /* background-color: rgba(207, 189, 132,0.6); */
  /* margin-top: 5vh; */
  /* font-size: 35px; */
  width: max-content
}
.dashboard-top {
  font-size: 25px;
  text-align: center;
  background-color: #8c4848;
  margin-bottom: 25px;
  opacity: 70%;
}

.dashboard-left {
  /* background-color: #53a39f; */
  /* padding: 36px 0px;
  margin-left: 8px; */
  /* border-radius: 10px; */
  width: max-content;
}

.profile {
  /* margin: 5px; */
  font-weight: bold;
  /* width:75%; */
  border-radius: 10px;
  /* min-width: 25vw; */
  
  font-size: large;
  /* padding: 5px; */
  margin-bottom: 24px;
}

.profile-data {
  margin: 5px;
  padding: 2px;
}

.profile-data span {
  color: rgb(26, 0, 140);
}

.update {
  width: 50%;
}

.update-btn {
  min-width: 25vw;
  margin: 5px;
}

.chat {
  position: fixed;
  bottom: 20px;
  right: 60px;
  /* width: auto; */
}

.chat-icon {
  font-size: 100px;
}

.profile-update {
  width: 50%;
  
  margin: auto;
}

.fixed-position-navbar {
  position: sticky;
  left: 0%;
  top: 0%;
  z-index: 1;
}

.fixed-position {
  position: sticky;
  /* left: 25%; */
  top: 9%;
  z-index: 1;
  margin: auto;
}

.chat-section {
  /* margin-top: 20px; */
  
  /* margin: auto; */
  width: 100%;
  overflow-y: auto;
  height: 70vh;
}

#background-image {
  height: 91.5vh;
  /* width: 100%; */
  /* decreasing opacity of image */
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../img/dashboard.jpeg") center/cover no-repeat;
  align-self: stretch;
  overflow-x: hidden; /*hiding overflow in width*/
}


#chat-left{
  background-color: rgb(22 56 19 / 47%);
  width: 36%;
  position: fixed;
  right: 5px;
  max-height: 500px;
  /* left: 900px; */

}

.dash{
  width: 100%;
}

@media (max-width: 768px){
  .dashboard {
    display: flex;
    flex-direction: column;
    align-items:center;
  }
  .dashboard-left{
    float: none;
    width: 100%;
    margin-bottom: 32px;
  }
  .dashboard-right{
    float: none;
    width: 100%;
  }
}